import { inject }                       from 'aurelia-framework';
import { AggregatesLabTestsRepository } from 'modules/aggregates/lab/services/repository';
import { TracksRepository }             from 'modules/aggregates/lab/services/tracks-repository';
import { ConstructionSchema }           from 'modules/aggregates/lab/tab-schemas/construction-schema';
import { LanesRepository }              from 'modules/management/bituminous-mixtures/lanes/services/repository';
import { OEDatasRepository }            from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { LotInterventionsRepository }   from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';

@inject(LotInterventionsRepository, OEDatasRepository, TracksRepository, AggregatesLabTestsRepository, LanesRepository)
export class HarvestDataSchema extends ConstructionSchema {

    schemaSlug = 'harvest_data';

    constructor(lotInterventionsRepository, oeDatasRepository, tracksRepository, aggregatesLabTestsRepository, lanesRepository) {
        super(lotInterventionsRepository, oeDatasRepository, tracksRepository);
        this.aggregatesLabTestsRepository = aggregatesLabTestsRepository;
        this.lanesRepository = lanesRepository;
    }

    /**
     * Returns form schema for "Harvest Data" (or "Work Control" for In Situ) tab
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    async schema(viewModel, readonly = false) {

        // Set ConstructionSchema fields
        await super.schema(viewModel, readonly);

        // Edit labels for In Situ
        if(viewModel.type === 'in-situ') {
            this.collection_pk.label = 'form.field.test-sample-pk';
            this.applied_pk.label = 'form.field.test-sample-pk';
        }

        this.axis_distance = {
            type:       'number',
            key:        'axis_distance',
            label:      'form.field.axis-distance(m)',
            required: false,
            size:       6,
            attributes: {
                readonly: readonly,
            },
        }

        // (only shown in Lab)
        this.ref_aggregates_lab_tests_ids = {
            type:         'duallistbox',
            key:          'ref_aggregates_lab_tests_ids',
            label:        'form.field.aggregates-samples-ref',
            remoteSource: () => this.aggregatesLabTestsRepository.validatedByCurrentLotIntervention(),
            required: false,
            size:         12,
            attributes:   {
                disabled: readonly,
            },
        };

        // (only shown in InSitu)
        this.lane_id = {
            type:         'select2',
            key:          'lane_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.lane',
            size:         6,
            remoteSource: () => this.lanesRepository.all(),
            required: false,
            attributes:   {
                disabled: true, // TODO standing-by migration
            },
        }

        // (only shown in InSitu)
        this.bmb_ref_id = {
            type:         'select2',
            key:          'bmb_ref_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.bmb-reference-sample-num',
            size:         10,
            remoteSource: () => viewModel.repository.validatedLabByLotIntervention(),
            required: false,
            attributes:   {
                disabled: readonly,
            },
        }

        // (only shown in InSitu)
        this.bmt_ref_id = {
            type:         'select2',
            key:          'bmt_ref_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.bmt-reference-sample-num',
            size:         10,
            remoteSource: () => viewModel.repository.validatedLabByLotIntervention(),
            required: false,
            attributes:   {
                disabled: readonly,
            },
        }

        return viewModel.type === 'lab'
            ? [
                [this.lot_construction_id, this.branch_id, this.edge_id],
                [this.oe_data_id, this.collection_pk, this.track_id],
                [this.number_of_tracks, this.axis],
                [this.axis_distance],
                [this.ref_aggregates_lab_tests_ids],
            ] : [
                [this.lot_construction_id, this.branch_id, this.edge_id],
                [this.oe_data_id, this.collection_pk, this.applied_pk],
                [this.number_of_tracks, this.axis],
                [this.track_id, this.lane_id],
                [this.axis_distance],
                [this.bmb_ref_id],
                [this.bmt_ref_id],
            ];
    }
}
